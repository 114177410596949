.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  /*height: calc(100vh - 210px);*/

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: inset 1px 2px 2px 0 rgba(255, 255, 255, 0.1);
  }
  table {
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
  }  
}

.table-chart {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  /*overflow-y: hidden;
  overflow-x: auto;*/
  /*overflow: auto;
  max-height: calc(100vh - 100px);*/

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: inset 1px 2px 2px 0 rgba(255, 255, 255, 0.1);
  }

  table {
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    td{ vertical-align: top;}
  }
  th, td {
    padding: 2px 7px;
    text-align: center;
    border-bottom: 1px solid #DDD;
    vertical-align: middle;
    max-width: 34px;
    min-width: 34px;
    width: 34px;
    cursor: pointer;
  }
  th:first-child, td:first-child {
    width: 300px;
    text-align: left;
    max-width: 350px;
    min-width: 350px;
    position: sticky;
    left: 0;
    padding-left: 24px;
    background-color: #FFF;
  }

  thead{position: relative; z-index: 1;
    th {
    font-size: 9px;
    letter-spacing: 1px;
    text-transform: uppercase;
    }
  }
}

.table-chart-sml {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: auto;  

  table {
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
  }
  th, td {
    padding: 7px 7px;
    text-align: center;
    border: 1px solid #DDD;
    vertical-align: middle;
    max-width: 30px;
    min-width: 30px;
    width: 30px;
    cursor: pointer;
  }
  
  thead th {
    font-size: 9px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border:1px solid #EEE;
    vertical-align: middle;
  }
}

.table-wrap {
  position: relative;
}

.table-scroll {
  th, td {
    padding: 10px;
    border-bottom: 1px solid #DDD;
    background: #fff;
  }
  td {vertical-align: top;}

  tbody tr:hover td {
    background-color: #FEF5EF;
  }
  thead th {
    background: #efefef;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    font-size: 11px;
    color: #777;
    letter-spacing: 1px;
    text-transform: uppercase;
    vertical-align: text-bottom;
  }

  tfoot {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #333;
    color: #fff;
    z-index: 4;

    th, td {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      background: #333;
      color: #fff;
      z-index: 4;
    }
  }
}

/* safari and ios need the tfoot itself to be position:sticky also */

th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}
.scroller table {
  min-width: 1280px;
}
.scroller table td, .scroller table th, .table-chart table td, .table-chart table th  {
  border-left: 1px solid #DDD;
}

.scroller {
  tfoot th:first-child {
    background-color: #000;
    color: #FFF;
  }
  .chosen-container .chosen-drop {
      border-bottom: 0;
      border-top: 1px solid #aaa;
      top: auto;
      bottom: 40px;
      font-size: 0.9em;
  }
  .chosen-container-single .chosen-single span {
    font-size: 0.9em;
    color: #000;
  }
  .chosen-container-single .chosen-single {
    background-color: #FFF;
  }
  .form-control {
    width: 100px;
    margin-left: 20px;
  }
}

.scroller-2 table {
  min-width: 1280px;
}
.scroller-2 table td, .scroller-2 table th {
  border-left: 1px solid #DDD;
}

.scroller-2 {
  th:first-child {
    background-color: #efefef;
    color: #000;
    border-left: none;
    font-weight: normal;
  }
  thead th {
    background-color: #333;
    color: #FFF;
  }
  thead th:first-child {
    background-color: #000;
    color: #FFF;
    min-width: 400px;
  }
  tfoot th:first-child {
    background-color: #000;
    color: #FFF;
    
  }
  .chosen-container .chosen-drop {
      border-bottom: 0;
      border-top: 1px solid #aaa;
      top: auto;
      bottom: 40px;
      font-size: 0.9em;
  }
  .chosen-container-single .chosen-single span {
    font-size: 0.9em;
    color: #000;
  }
  .chosen-container-single .chosen-single {
    background-color: #FFF;
  }
  .form-control {
    width: 100px;
    margin-left: 20px;
  }
}
.h-auto {
  height:auto;
}
.orders {
  .card-header {
    font-weight:600;
    background-color: #333;
    color: #FFF;

  }
  .table {
    margin-bottom: 0;
    width: 100%;
    font-size: 0.9em;
    td{
      vertical-align: middle;
    }
  }
  thead th {
    border-top: 0;
  }
  .scrollable-panel {    
    padding: 0;
    height: 350px;
    overflow-y: auto;
    thead {
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 1;
      background-color: #FFF;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }
    tfoot {
      position: sticky;
      bottom: 0;
      width: 100%;
      z-index: 1;
      background-color: #EEE;

    }
  }
  .card {
    margin-bottom: 30px;
  }
}
.item-table {
  table {
    width: 100%;
    th {
      text-transform: uppercase;
      color: #777;
      letter-spacing: 1px;
      font-weight: 600;
      font-size:12px;
      padding: 10px;
      vertical-align: middle;
      background-color: #efefef;
    }
    td {
      padding: 10px;
      border-bottom: 1px solid #DDD;
      vertical-align: middle;
    }
    th, td {
      width: 100px;
      &:first-child {
        width:85%;
      }
    }
    .item-name {
      font-weight: 600;
      color: #223d66;
      display: block;
    }
    .item-price {
      font-size: 0.9em;
    }
    tfoot {
      td {
        font-weight: 600;
      }
    }
    .sml-input {
      float: none;
      margin-right: 10px;
      text-align: right;
    }
  }
}
.table td, .table th {
  vertical-align: middle;
}
.main-table img {
  border:1px solid #DDD;
  border-radius: 4px;
}

.scroller {
  tbody {
    th {
      font-weight: bold;
    }
  }
  .kit-row {
    td:first-child {
      padding-left:45px;
    }
  }
}
.albl {
  background-color: #A2B5F8;
}
.bkd {
  background-color: #F17A7A;
}
.tbr {
  background-color: #000;
}
.dbkd {
  background-color: #F9E212;
}
.csnmt {
  background-color: #EE7EF0;
}
.cmgtd {
  background-color: #FFA500;
}
.stwrng {
  background-color: brown;
}
.tdy {
  background-color: $color__primary;
  color: #FFF;
}
.cat-row td {
  border:none !important;
}
.mnth-srl {
  display:flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}
.headrow {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}
.headrow td {
  padding: 10px;
}
.united {
  border-left: none !important;
}
.holiday {
  background-color: red;
  color: #FFF;
  border-color: red;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.table_content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table_content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.overflow-hidden{ overflow: hidden;}

/*.table-chart{
  &.scroller{overflow-x: auto; overflow-y: hidden; max-height: calc(100vh - 147px) !important;}
  .table_content{max-height: calc(100vh - 235px) !important; overflow-y: auto;}
}*/

.table-chart.scroller{ overflow: auto; height: fit-content; max-height: calc(100vh - 150px) !important;
  table{ min-width: max-content;}
  .table_header{ position: relative; z-index: 3; background: #fff;
    td,th{position: sticky; background: #fff;}
    td{ top: 0;
      &:first-child{ z-index: 4;}
    }
    th{ top: 39px;
      &.holiday {background-color: red;}
      &.tdy {background-color: #444fe5;}
    }
  }
}
.table-chart th{border-top: 1px solid #ddd; padding: 2px 5px; text-align: center; letter-spacing: 0px !important; width: 225px;
  &.first_col:first-child{ width: 30px; max-width: 30px; min-width: 30px; padding: 2px 5px; text-align: center; letter-spacing: 0px !important; }
  &:last-child{border-right: 1px solid #ddd;  width: 32px; max-width: 34px; min-width: 32px; padding: 2px 5px; text-align: center; letter-spacing: 0px !important;}
}

.avail-chart.overflow-hidden{ min-height:400px;}
.table-scroll{ min-height:330px;}
.table-chart th:nth-child(2){ padding-right: 0px; }